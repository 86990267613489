import React, { useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, Form, Badge,Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { USER_PROFILE } from '../../../Graphql/Queries/Costumer';
import { formatTarih } from '../../../Helper/TimeHelper'
import { formatString } from '../../../Helper/StringHelper'
import { checkToken } from '../../../services/Auth'

import { BsDownload } from "react-icons/bs";
import ProuctCard from './ProductCard'
import etex from "../../../Assets/Projects/etex.png";
import netex from "../../../Assets/Projects/netex.png";
import betonarmearaclar from "../../../Assets/Projects/betonarmearaclar.png";

const Costumer = () => {
    const navigate = useNavigate();
    let userId = localStorage.getItem('currentUserId')
    const { loading, error, data, refetch } = useQuery(USER_PROFILE, { variables: { userId } });
      
    useEffect(() => {
        refetch()
        if (!checkToken()) {
            navigate('/login')
        }
    }, [refetch])

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return (
            <Container>
            <Row style={{ marginTop: '15%' }} className="vh-100 d-flex justify-content-center ">
                <Col md={12} lg={12} xs={12} style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 10 }}>
             
                    Error: {error.message}
                </Col>
            </Row>
        </Container>);
    }

    const user = data?.userProfile.costumer;
    const products = data?.userProfile.licences;
    const machines = data?.userProfile.machines;
 

    return (
        <Container>
            <Row style={{ marginTop: '7.5%' }} className="vh-100 d-flex justify-content-center ">
                <Col md={12} lg={12} xs={12} style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 10 }}>
                    <Card style={{ width: '100%', backgroundColor: '#C8C6CD', borderRadius: '5px', padding: '15px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        <Card.Body>
                            <Card.Title style={{ fontSize: '1.25rem', marginBottom: '10px' }}>Kullanıcı Bilgileri</Card.Title>
                            <Card.Text style={{ fontSize: '1rem', marginBottom: '5px' }}>
                                <strong>Ad:</strong> {user.username}
                            </Card.Text>
                            <Card.Text style={{ fontSize: '1rem', marginBottom: '5px' }}>
                                <strong>E-posta:</strong> {user.email}
                            </Card.Text>
                            <Card.Text style={{ fontSize: '1rem', marginBottom: '5px' }}>
                                <strong>Firma:</strong> {user.company}
                            </Card.Text>
                            <Button variant="primary" href="/changepassword" style={{ marginTop: '5px', fontSize: '0.9rem' }}>
                                Parola Değiştir
                            </Button>
                        </Card.Body>
                    </Card>
                    <Row className="d-flex justify-content-around ">
                        <ProuctCard appName="ETEX" appContent="Doğrusal hesaplar için Etabs-Excel API yardımcı araçlar." imagePath={etex} appPrice="35.000 TL/Yıl" appHref={`https://api.whatsapp.com/send?phone=+905539878725&text=Merhaba, ben ${user.username}, Etex lisansı talebi`}/>
                        <ProuctCard appName="NETEX" appContent="Performan analizi için Etabs-Excel API yardımcı araçlar." imagePath={netex} appPrice="45.000 TL/Yıl" appHref={`https://api.whatsapp.com/send?phone=+905539878725&text=Merhaba, ben ${user.username}, Netex lisansı talebi`}/>
                        <ProuctCard appName="BetonarmeAraclar" appContent="Uygulama Projesi Detay Çizimleri - Revit - AutoCAD ." imagePath={betonarmearaclar} appPrice="50.000 TL/Yıl" appHref={`https://api.whatsapp.com/send?phone=+905539878725&text=Merhaba, ben ${user.username}, Betonarme Araçlar lisans talebi`}/>
                    </Row>
                </Col>
                <Col style={{ marginTop: '5%' }} md={8} lg={8} xs={12}>
                    <h3 style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 10 }}>Ürün Listesi</h3>
                    <ListGroup >
                        {products.map((product) => (
                            <ListGroup.Item key={product._id}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h5>{product.productionCode}</h5>
                                    <span>{product.appVersion}</span>
                                    <span>{product.maximumNumberOfMachines}</span>
                                    <span>{formatTarih(product.expirationDate)}</span>
                                    <span>{formatString(product._id)}</span>
                                    <Button variant="light" href='https://drive.google.com/drive/folders/1qLTKLsdZv-PZI_NhCszq-Yb-rW1mD4J2?usp=sharing'><BsDownload /></Button>
                                    <Badge bg={product.featuresF1?"danger":"success"}>{product.featuresF1?"Demo":"Lisans"}</Badge>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col style={{ marginTop: '5%' }} md={4} lg={4} xs={12}>
                    <h3 style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 10 }}>Makine Listesi</h3>
                    <ListGroup>
                        {machines.map((machine) => (
                            <ListGroup.Item key={machine._id}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h5>{machine.hostname}</h5>
                                    <span>{machine.ipAddressOut}</span>
                                    <span>{machine.platform}</span>
                                    <div>
                                        <Form>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={!machine.isBlocked}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
};
export default Costumer;